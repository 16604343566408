<template>
  <div class="app-main-content" ref="main">
    <div class="container d-flex">
      <settings-left-menu></settings-left-menu>
      <card title="历史信息" style="width: calc(100% - 326px)">
        <template #tools>
          <div class="right">
            <div class="item">
              <span class="label">搜索</span>
              <a-input class="search-input" v-model="keywords" placeholder="请输入关键字">
                <a-icon slot="prefix" type="search" />
              </a-input>
            </div>
            <div class="item">
              <a-button class="search" @click="onSearch">查询</a-button>
            </div>
          </div>
        </template>
        <div class="main">
          <div style="margin: 0 20px 20px">
            <a-table
                tableLayout="fixed"
                :data-source="historyInfoData"
                :pagination="pagination"
                @change="paginationChange"
                rowKey="oid"
            >
              <a-table-column title="发送人" data-index="sender_name" width="200px">
                <template #default="_,row">
                  {{ row.sender_title ? row.sender_name + '-' + row.sender_title : sender_name }}
                </template>
              </a-table-column>
              <a-table-column title="接收人" data-index="receiver_name" width="200px">
                <template #default="_,row">
                  {{ row.receiver_title ? row.receiver_name + '-' + row.receiver_title : receiver_name }}
                </template>
              </a-table-column>
              <a-table-column title="发送内容" :ellipsis="true">
                <template #default="{content}">
                  <a-tooltip>
                    <template slot="title">
                      {{ content }}
                    </template>
                    {{ content }}
                  </a-tooltip>
                </template>
              </a-table-column>
              <a-table-column title="项目名称" data-index="project_name" width="250px"></a-table-column>
              <a-table-column title="关联任务" data-index="issue_name" width="250px"></a-table-column>
              <a-table-column title="发送时间" data-index="send_time" width="170px"></a-table-column>
            </a-table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { fetch } from "@/utils/request";
import settingsLeftMenu from "@/components/settingsLeftMenu.vue";

export default {
  data() {
    return {
      historyInfoData: [],
      // 搜索关键值
      keywords: '',
      pageParams: {
        // 当前页
        page_index: 1,
        // 当前条
        page_size: 10,
        // 总条
        total: 0
      }
    }
  },
  components: { settingsLeftMenu },
  created() {
    this.getHistoryInfoData()
  },
  computed: {
    pagination() {
      return {
        current: this.pageParams.page_index,
        pageSize: this.pageParams.page_size,
        showSizeChanger: true,
        total: this.pageParams.total,
        hideOnSinglePage: false
      }
    }
  },
  methods: {
    // 获取页面基础数据
    async getHistoryInfoData() {
      const res = await fetch('post', '/wx/push/his/retrieve', {
        keywords: this.keywords,
        page_index: this.pageParams.page_index,
        page_size: this.pageParams.page_size
      })
      console.log(res)
      if (res.status === 0) {
        this.historyInfoData = res.data.datas
        this.pageParams.total = res.data.page.total
      }
    },
    // 页码跟条数发生变化
    paginationChange(pagination) {
      this.pageParams.page_index = pagination.current
      this.pageParams.page_size = pagination.pageSize
      this.getHistoryInfoData()
    },
    // 搜索
    onSearch() {
      this.page_index = 1
      this.getHistoryInfoData()
    }
  }
}
</script>

<style lang="less" scoped>
.app-main-content {
  .container {
    width: 100%;
    height: 100%;

    /deep/ .edp-card-body {
      overflow-y: auto;
    }
  }

  .main {
    .operate {
      display: flex;
      justify-content: right;
      align-items: center;
      margin: 14px 34px 14px 20px;

      .left {
        .search {
          width: 228px;
          height: 26px;

          /deep/ .ant-input {
            padding: 0;
            height: 26px;
          }

          /deep/ .ant-input-suffix {
            font-size: 17px;
            right: 24px;

            .ant-input-search-icon {
              color: rgba(0, 0, 0, 0.8);
            }
          }
        }
      }

      .right {
        .button {
          height: 30px;
          border-radius: 3px;
          color: #fff;
          background-color: var(--primary-color);
          font-weight: 500;
        }
      }
    }

    /deep/ .ant-table-wrapper {
      .ant-table-pagination {
        float: none !important;
        text-align: center;
      }
    }
  }
}
</style>
